.selected-roles-list, .all-roles-list{
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(217 217 217);

    .ant-spin-nested-loading {
        overflow: auto;
        height: 100%;
    }
}


.selected-roles-list{
    height: 300px;

    .ant-list-header{
        padding: 0.5rem 1rem;
    }

    .ant-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;

        span{
            width: 90%;
           
        }
    }

    .ant-list-footer{
        padding: 0;

        .role-form{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0.5rem;
        }

    }
}



.all-roles-list{
    height: 260px;

    .checkbox-label{
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;

        &::after{
        content: unset;
        }
    }
}