.login-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .login-card {
    width: 400px;

    .login-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      margin-bottom: 24px;

      .image {
        width: 56px;
        height: 56px;
        margin-right: 8px;
      }

      .title-container {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 14px;
        }
      }
    }

    .site-form-item-icon {
      color: rgb(0 0 0 / 25%);
    }

    .login-form-button {
      width: 100%;

      &.tsu-accounts-login-button {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }

    .login-form-last-item {
      margin: 0;
    }

    .small-margin-bottom {
      margin-bottom: 4px;
    }

    .buttons-divider {
      text-align: center;
    }
  }
}