.delete-field-button-wrapper {
  svg {
    color: red
  }

  &.disabled {
    svg {
      cursor: not-allowed;
      color: rgb(126 124 124);
    }
  }
}

.row-wrapper {
  align-items: stretch;
  height: 350px;

  >div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .select-all-audiences-button {
    margin-bottom: 16px;
  }

  .list-wrapper {
    overflow-y: auto;
    height: 100%;

    .list-header {
      * {
        margin: 0;
      }

    }

    .ant-list {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ant-spin-nested-loading {
        overflow-y: auto;
        flex: 1;
      }

      .ant-list-items {
        li.ant-list-item {
          justify-content: flex-end;
          padding: 0;

          label {
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding: 8px 16px;

            &::after {
              content: none;
            }
          }

          .content-wrapper {
            width: 100%;
            margin: 8px;
          }

          .delete-field-button-wrapper {
            padding-right: 16px;
          }
        }

      }
    }
  }
}

.newRoleInputSearch {
  >span {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >span {
      /* stylelint-disable-next-line declaration-no-important */
      width: 100% !important;

      >button {
        width: 100%;
      }
    }
  }
}