.main-layout{
  >aside{
    .logo{
      display: flex;
      gap: 0.25rem;
      align-items: center;

      padding-top: 0.5rem;
      padding-left: 1.3rem;

      color: white;

      svg {
        min-width: 2.5rem;
        min-height: 2.5rem;
       
      }

      .title-wrapper{
        text-wrap: nowrap;

        h5, span{
          margin: 0;
          color: white;
        }

        span{
          font-size: 0.625rem
        }
      }


      &.collapsed{
        .title-wrapper{
          visibility: hidden;
        }
      }
     
    }
  }

  main{
    margin: 0 1rem;
  }

  footer{
    text-align: center;
  }
}