.users-page-container {
  margin-top: 16px;

  .users-content-container {
    min-width: 672px;
    max-width: 1024px;

    .search-container {
      display: flex;
      flex-direction: row;
      gap:16px;
      margin-bottom: 16px;

      .search-input {
        flex: 1;
        margin-right: 16px;
      }
    }

    .roles-list-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      margin-top: 8px;

      .role-list-item {
        width: 100%;
        padding: 4px;

        text-align: center;

        background-color: #fafafa;
        border: 1px solid #f0f0f0;
      }
    }
  }
}

.delete-all-roles-popover-wrapper {
  display: flex;
  justify-content: space-around;
}