.audience-groups-page-container {
  margin-top: 16px;

  .header-wrapper {
    display: flex;
    justify-content: space-between;

    .search-input {
      max-width: 576px;
      margin-bottom: 16px;
    }
  }


  .audience-groups-content-container {
    justify-content: space-between;

    .ant-table-cell {
      padding: 0;
    }

    .audience-groups-list-content {
      cursor: pointer;
      padding: 16px;

      .ant-typography {
        white-space: pre-wrap;
      }

      * {
        margin: 0;
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }


    .additional-fields {
      overflow-y: auto;
      max-height: 450px;

      .additional-filed-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 8px;
        margin-bottom: 16px;
        padding: 8px;

        border: 1px dashed #40a9ff;
        border-radius: 2px;

        .additional-filed-input-wrapper-formItems {
          display: inherit;
          flex-direction: column;

          .form-items-row {
            display: inherit;
            flex-direction: row;
            gap: 16px;

            .form-items-row-type-selector {
              width: 130px;
            }
          }

          .form-items-checkbox-wrapper {
            margin-bottom: 0;
          }
        }

        .delete-field-button-wrapper {
          margin-left: 32px;
        }
      }

      .add-field-button-wrapper {
        margin: 8px;

        button {
          padding: 0;
        }
      }
    }
  }

  .message-form {
    margin: 0;
    margin-top: 8x;

    .message-form-switch-wrapper {
      margin-bottom: 16px;

      :first-child {
        margin-right: 16px;
      }
    }

    .message-form-redactor {
      margin-top: 16px;

      :first-child {
        margin-bottom: 16px;
      }
    }
  }

  .result-wrapper {
    padding: 8px;
  }
}