.additional-fields-list{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    max-height: 400px;

    .additional-field-wrapper{
        padding: 1rem;
        border: 1px dashed #40a9ff;
    }

}
