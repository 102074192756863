.selected-audiences-list, .all-audiences-list{
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(217 217 217);

    .ant-spin-nested-loading {
        overflow: auto;
    }
}


.selected-audiences-list{
    height: 340px;

    .ant-list-header{
        padding: 0.5rem 1rem;
    }

    .ant-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;

        span{
            width: 90%;
           
        }
    }
}

.all-audiences-list{
    height: 260px;

    .checkbox-label{
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;

        &::after{
        content: unset;
        }
    }
}