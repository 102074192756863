.booking-applications-page-container {
  margin-top: 16px;

  .booking-applications-content-container {
    min-width: 672px;
    max-width: 1024px;

    .booking-applications-search-input {
      max-width: 576px;
      margin-bottom: 16px;
    }

    .booking-applications-master-detail-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .booking-applications-list {
        width: 320px;
        margin-right: 16px;

        .ant-table-pagination {
          margin-bottom: 0;
        }

        .booking-applications-list-row {
          cursor: pointer;

          .ant-table-cell {
            padding: 0;
          }

          .booking-applications-list-row-content {
            display: flex;
            flex-direction: column;
            width: 288px;
            padding: 16px;

            .title {
              margin-bottom: 4px;
              font-weight: bold;
            }

            .info-container {
              display: flex;
              justify-content: space-between;

              .date {
                font-size: 12px;
                opacity: 0.6;
              }
            }
          }
        }
      }

      .booking-applications-details {
        width: 100%;

        .ant-card-head-title {
          word-break: break-word;
          white-space: normal;
        }

        .booking-applications-top-container {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;

          .user-info {
            display: flex;
            flex-direction: row;
            margin-bottom: 16px;

            .avatar {
              margin-right: 8px;
            }

            .user-description-container {
              display: flex;
              flex-direction: column;

              .user-name {
                font-weight: bold;
              }
            }
          }
        }

        .actions-container {
          margin-top: 16px;

          button:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}